import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const InfoContext = createContext();

export const InfoProvider = ({ children }) => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://personal-website-backend-l036.onrender.com/api/viewinfo/viewinfo');
        setInfo(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <InfoContext.Provider value={{ info, loading, error }}>
      {children}
    </InfoContext.Provider>
  );
};