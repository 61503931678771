import React, { useContext, useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import {Link} from "react-router-dom"
import { InfoContext } from "../../context/InfoContext";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { info } = useContext(InfoContext);
  const logo_image = info.length ? info[0].logo_image : [];
  return (
    <div className="sticky top-0 z-50">
      <nav className="navbar flex flex-wrap items-center justify-between bg-blue-900 p-6">
        {/* Logo */}
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <img
            className="fill-current h-8 w-8 mr-2 rounded-full"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            src= {logo_image}
            alt=""
          />
          <span className="font-semibold text-xl tracking-tight">
           <Link to="/home">Niroj Bhattarai</Link> 
          </span>
        </div>

        {/* Toggle Button for Small Screens */}
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="flex items-center px-3 py-2 border rounded text-blue-300 border-blue-300 hover:text-white hover:border-white"
          >
            {isOpen ? (
              <XIcon className="h-6 w-6" />
            ) : (
              <MenuIcon className="h-6 w-6" />
            )}
          </button>
        </div>

        {/* Menu Items */}
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } w-full flex-grow lg:flex lg:items-center lg:w-auto mt-4 lg:mt-0`}
        >
          <div className="text-sm lg:flex-grow">
            <Link
              to="/home"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-300 hover:text-white mr-4"
            >
              Home
            </Link>
            <Link
              to="/projects"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-300 hover:text-white mr-4"
            >
              Projects
            </Link>

            <Link
              to="/blogs"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-300 hover:text-white mr-4"
            >
              Blogs
            </Link>

            <Link
              to="/contacts"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-300 hover:text-white mr-4"
            >
              Contacts
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
