import "./App.css";
import Layout from "./Components/Layout/Layout";
import { InfoProvider } from "./context/InfoContext";

function App() {
  return (
    <InfoProvider>
      <Layout />
    </InfoProvider>
  );
}

export default App;
