import { React, Fragment } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../../Router/Routers";

export default function Layout() {
  return (
    <Fragment>
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="flex-grow">
          <Routers />
        </div>
        <Footer />
      </div>
    </Fragment>
  );
}
