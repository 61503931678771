import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ProjectProvider } from "../context/ProjectContext";
import { BlogProvider } from "../context/BlogContext";
import { InfoProvider } from "../context/InfoContext";

const Home = lazy(() => import("../Pages/Home"));
const Projects = lazy(() => import("../Pages/Projects"));
const Contacts = lazy(() => import("../Pages/Contacts"));
const Blogs = lazy(() => import("../Pages/Blogs"));
const BlogDetail = lazy(() => import("../Components/UI/BlogDetail"));

function Routers() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {/* Move the providers here */}
      <ProjectProvider>
        <BlogProvider>
          <InfoProvider>
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/:id" element={<BlogDetail />} />
            </Routes>
          </InfoProvider>
        </BlogProvider>
      </ProjectProvider>
    </Suspense>
  );
}

export default Routers;
